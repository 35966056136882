import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const lastUpdated = localStorage.getItem("lastUpdated")
    document.getElementById("last_updated").innerText = `Last Updated: ${lastUpdated}`
    let db;
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;
    };

    DBOpenRequest.onupgradeneeded = function () {
      db = DBOpenRequest.result;

      const storeMeters = db.createObjectStore("meters", { keyPath: "id" });
      const storeReadings = db.createObjectStore("readings", { keyPath: "id" });
      const storeBuildings = db.createObjectStore("buildings", { keyPath: "id" });
      const storeGroups = db.createObjectStore("groups", { keyPath: "id" });
      storeReadings.createIndex("meter_id", "meter_id", {unique: false});
      storeMeters.createIndex("business_id", "business_id", {unique: false});
    };

    let touchstartY = 0;
    document.addEventListener('touchstart', e => {
      touchstartY = e.touches[0].clientY;
    });

    if (window.navigator.onLine) {
      document.addEventListener('touchmove', e => {
        const touchY = e.touches[0].clientY;
        const touchDiff = touchY - touchstartY;
        if (touchDiff > 0 && window.scrollY === 0) {
          document.querySelector('.pull-to-refresh').classList.add('visible');
        }
      });
    }

    document.addEventListener('touchend', e => {
      if (document.querySelector('.pull-to-refresh').classList.contains('visible')) {
        this.clearData(db);
        const date = new Date(Date.now());
        localStorage.setItem("lastUpdated", date.toLocaleString());
        document.querySelector('.pull-to-refresh').classList.remove('visible');
        const href = window.location.href;
        const useIndexDBParam = "use_index_db=true";
        const search = window.location.search
        if (href.includes(useIndexDBParam)) {
          location.reload();
        } else {
          window.location.replace(`${href}${search.length > 0 ? "&" : "?"}${useIndexDBParam}`);
        }
      }
    });
  }

  clearData(db) {
    db.transaction("meters", "readwrite").objectStore("meters").clear();
    db.transaction("buildings", "readwrite").objectStore("buildings").clear();
    db.transaction("groups", "readwrite").objectStore("groups").clear();
    db.transaction("readings", "readwrite").objectStore("readings").clear();
  }
}
