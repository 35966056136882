<template>
  <div v-if="(fuelGroup == 'footprint' && offsetting == 'false' && byTargets == 'false' || fuelGroup == 'procurement' || byScope == 'true') && !scope2Comparisson" class="button-filters report">
    <span>Report</span>
    <div
         :class="['icon filter-icon circled tooltip location', { 'icon-active': reportType == 'location' }]"
         @click="urlModifier('report_type', 'location')">
      <inline-svg :src="require('images/icons/carbon_location_based.svg')"></inline-svg>
      <span class="tooltiptext">Location-based emissions</span>
    </div>
    <div
         :class="['icon filter-icon circled tooltip market', { 'icon-active': reportType == 'market' }]"
         @click="urlModifier('report_type', 'market')">
      <inline-svg :src="require('images/icons/carbon_market_based.svg')"></inline-svg>
      <span class="tooltiptext">Market-based emissions</span>
    </div>

  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'
  export default {
    props: {
      defaultParams: Object,
    },

    methods: {
      urlModifier: function(parameter, value) {
        let url = new URL(window.location.href);
        url.searchParams.set(parameter, value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.fuelGroup = this.defaultParams['fuel_group'];
      this.byScope = url.searchParams.get('by_scope');
      this.byTargets = url.pathname.includes('targets');
      this.offsetting = this.defaultParams['offsetting'];
      this.reportType = url.searchParams.get('report_type') || this.defaultParams['report_type'];
      this.scope2Comparisson = url.searchParams.get('scope') == 'scope_2_comparisson'
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
