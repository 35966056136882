<template>
  <div v-if="carbonFootprints && !scope2Comparisson" class="button-filters group-by">
    <span>Group by</span>
    <div v-if="!(byScope && chartType == 'pie')" :class="['icon filter-icon tooltip circled group', { 'icon-active': estate }]" @click="urlModifier('true')">
      <inline-svg :src="require('images/icons/countries_grouping.svg')"></inline-svg>
      <span class="tooltiptext">Company</span>
    </div>
    <div :class="['icon filter-icon circled tooltip business', { 'icon-active': !estate }]" @click="urlModifier('false')">
      <inline-svg :src="require('images/icons/group_grouping.svg')"></inline-svg>
      <span class="tooltiptext">Group</span>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      defaultParams: Object,
      carbonFootprints: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        estate: null,
        chartType: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);

        if (value == 'group') {
          url.searchParams.delete('to');
        }

        url.searchParams.set('estate', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.estate = url.searchParams.get('estate') == 'true';
      this.chartType = url.searchParams.get('chart_type') || 'line';
      this.byScope = url.searchParams.get('by_scope') == 'true';
      this.scope2Comparisson = url.searchParams.get('scope') == 'scope_2_comparisson'
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
