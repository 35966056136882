import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="token"
export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.addListeners();
  }

  addListeners() {
    this.inputTargets.forEach((input, index) => {
      input.addEventListener('input', () => {
        const maxLength = 1;
        const currentLength = input.value.length;

        if (currentLength >= maxLength) {
          this.focusNextInput(index);
        }
      });
    });
  }

  focusNextInput(index) {
    const nextIndex = index + 1;
    if (nextIndex < this.inputTargets.length) {
      this.inputTargets[nextIndex].focus();
    }
  }
}
