import { Controller } from "@hotwired/stimulus";
import { debounce } from "debounce";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    const url = new URL(window.location.href);
    const searchParam = url.searchParams.get('search');

    if (searchParam) {
      this.inputTarget.value = searchParam;
      this.inputTarget.focus();
    }

    this.search = debounce(this.search, 500)
  }

  search() {
    const url = new URL(window.location.href);
    url.searchParams.delete('search');
    url.searchParams.set('search', this.inputTarget.value);
    url.searchParams.sort();
    window.location.href = url;
  }
}
