import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label'];

  updateLabels(event) {
    this.labelTargets.forEach(label => {
      label.classList.remove('fw-bold');
    });

    const selectedValue = event.target.value
    const selectedLabel = document.querySelector(`label[for="${selectedValue}"]`);
    selectedLabel.classList.add('fw-bold')
  }
}
