import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input"];
  static values = { url: String, forceVisit: Boolean }

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener("change", this.fetchForm.bind(this));
    });
  }

  fetchForm() {
    if (this.forceVisitValue) {
      this.visitFormUrl();
    } else {
      this.fetchFromAsync();
    }
  }

  visitFormUrl() {
    Turbo.visit(this.urlWithQueryString())
  }

  async fetchFromAsync() {
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    return `${this.url()}?${this.queryString()}`;
  }

  url() {
    if (this.urlValue) {
      return this.urlValue ;
    } else {
      return window.location.href.split('?')[0];
    }
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams(window.location.search);
    for (const [name, value] of form.entries()) {
      const input = document.querySelector(`input[name="${name}"]`);

      if (name !== 'utf8' && (input === null || input.type !== 'file')) {
        params.delete(name)
        params.append(name, value);
      }
    }

    return params.toString();
  }
}
