import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.getData();
  }

  getData() {
    let db;
    let controller = this;
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;

      createTable()
    };

    function createTable() {
      const meterId = parseInt(document.getElementById("meter_id").value);
      const meterName = document.getElementById("meter_name").value;
      const businessId = document.getElementById("business_id").value;
      const businessSlug = document.getElementById("business_slug").value;
      const table = document.getElementById("meter_readings").getElementsByTagName("tbody")[0];
      const form = document.getElementById("form");
      const transaction = db.transaction(["readings"], "readwrite");
      const objectStore = transaction.objectStore("readings");
      const keyRange = IDBKeyRange.bound(meterId, meterId, false, false);
      const readingsIndex = objectStore.index("meter_id");
      const result = [];

      readingsIndex.openCursor(keyRange).onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          result.push(cursor.value);
          cursor.continue();
        } else {
          for (let index = 0; index < result.length; index++) {
            const reading = result[index];
            if (!reading.synched && localStorage.getItem("slowConnection") != "true") document.getElementById('sync-now-button').classList.remove('d-none')
            const parsedReadingId = parseInt(reading.reading_id)
            // TODO: separate in a different funciton
            const row = table.insertRow(index);
            row.classList.add("border-hidden");
            row.classList.add("h-60px")

            row.insertCell(0).innerHTML = reading.amount;
            row.cells[0].classList.add("text-decoration-underline");

            row.insertCell(1).innerHTML = controller.parseDate(reading.taken_at);
            // TODO: this will be changed for a column stored in IndexDb
            row.insertCell(2).innerHTML = `<span class="bg-custom-light-blue rounded-5 text-primary px-2 bg-opacity-50">${reading.synched ? 'Synched' : 'Saved'}</span>`;

            row.cells[0].classList.add("fs-larger");
            row.cells[1].classList.add("fs-larger");
            row.cells[2].classList.add("fs-larger");

            if (index % 2 !== 0) {
              row.cells[0].classList.add("bg-darker-gray");
              row.cells[1].classList.add("bg-darker-gray");
              row.cells[2].classList.add("bg-darker-gray");
            }

            row.cells[0].addEventListener("click", () => {
              if (window.navigator.onLine && localStorage.getItem("slowConnection") != "true") {
                const url = `${window.location.origin}/mobile/businesses/${businessSlug}/readings/${parsedReadingId}`
                document.getElementById("modal").classList.remove("hidden");
                document.getElementById("meter-information").innerHTML = `Meter ${meterName} on ${controller.parseDate(reading.taken_at)}`
                document.getElementById("amount").value = reading.amount;
                document.getElementById("delete-reading").href = url;
                document.getElementById("delete_amount").value = reading.amount;
                document.getElementById("delete_comment").value = reading.comment || '';
                form.method = 'PUT';
                form.action = url;
              } else {
                document.getElementById("offline_modal").classList.remove("hidden");
              }
            })
          }
        }
      };
    };
  };

  parseDate(date) {
    return date.substring(0, 10).split("-").reverse().join("/")
  };

  closeModal() {
    document.getElementById("modal").classList.add("hidden");
  };

  closeOfflineModal() {
    document.getElementById("offline_modal").classList.add("hidden");
  };

  toggleDeleteModal() {
    document.getElementById("delete_modal").classList.remove("hidden");
  }

  closeDeleteModal() {
    document.getElementById("delete_modal").classList.add("hidden");
    document.getElementById("modal").classList.add("hidden");
  }
};
