<template>
  <div v-if="submeters" class="button-filters">
    <span>League</span>
    <div :class="['icon filter-icon circled', { 'icon-active': submetersLeague }]" @click="urlModifier(!submetersLeague)">
      <inline-svg :src="require('images/icons/efficiency_by_size.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      submeters: {
        type: Boolean,
        default: false
      },
    },
    data: function() {
      return {
        submetersLeague: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);
        url.searchParams.set('league', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.submetersLeague = (url.searchParams.get('league') == 'true');
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
