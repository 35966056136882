<template>
  <div v-if="fuels" class="button-filters">
    <span>Data type</span>
    <div :class="['icon filter-icon circled', { 'icon-active': !airex && fuel.includes('temperature') }]" @click="urlModifier('fuels[]', 'temperature')">
      <inline-svg :src="require('images/icons/temperature.svg')"></inline-svg>
    </div>
    <div :class="['icon filter-icon circled', { 'icon-active': !airex && fuel.includes('humidity') }]" @click="urlModifier('fuels[]', 'humidity')">
      <inline-svg :src="require('images/icons/humidity.svg')"></inline-svg>
    </div>
    <div :class="['icon filter-icon circled', { 'icon-active': airex }]" @click="urlModifier('airex', !airex)">
      <inline-svg :src="require('images/icons/airex.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      defaultParams: Object,
      fuels: {
        type: Boolean,
        default: false
      },
    },
    data: function() {
      return {
        airex: null,
        fuel: null,
      }
    },
    methods: {
      urlModifier: function(parameter, value) {
        let url = new URL(window.location.href);

        if (parameter == 'airex' && value) {
          url.searchParams.delete('fuels[]');
        } else if (parameter == 'fuels[]') {
          url.searchParams.delete('airex');
        }

        url.searchParams.set(parameter, value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.airex = url.searchParams.get('airex') == 'true';
      if (url.searchParams.getAll('fuels[]').length) {
        this.fuel = url.searchParams.getAll('fuels[]')
      } else {
        this.fuel = this.defaultParams['fuels']
      }
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
