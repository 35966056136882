import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { siteKey: String }

  initialize() {
    const siteKey = this.siteKeyValue;
    grecaptcha.ready(function(){
      grecaptcha.render("recaptchaV2", {
        sitekey: siteKey
      });
    });
  }
}
