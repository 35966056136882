import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker';
import { merge } from '../utils/merge'

import 'vanillajs-datepicker/dist/css/datepicker-foundation.css';

export default class extends Controller {
  static targets = ["input"]
  static values = { options: Object }

  connect() {
    this.initializeDatepicker();
    this.initializeEvents();
  }

  defaultOptions() {
    return {
      autohide: true,
      weekStart: 1,
      format: 'dd-mm-yyyy',
      nextArrow: '>',
      prevArrow: '<',
      orientation: 'bottom auto',
    }
  }

  initializeDatepicker() {
    const datepickerOptions = merge(this.defaultOptions(), this.optionsValue);
    new Datepicker(this.inputTarget, datepickerOptions);
  }

  initializeEvents() {
    this.inputTarget.addEventListener('changeDate', (event) => {
      let changeEvent = new Event('change');
      this.inputTarget.dispatchEvent(changeEvent);
    })
  }
}
