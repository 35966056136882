import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);
    let db;

    DBOpenRequest.onsuccess = function () {
      db = DBOpenRequest.result;

      const transactionReadings = db.transaction("readings", "readwrite");
      const storeReadings = transactionReadings.objectStore("readings");

      const readings = document.getElementById("readings")
      const parsedReadings = JSON.parse(readings.getAttribute("data-readings"))
      if (!parsedReadings) return;

      for (let i = 0; i < parsedReadings.length; i++) {
        let reading = parsedReadings[i]
        storeReadings.put({ id: i, meter_id: reading.meter_id, taken_at: reading.taken_at, amount: reading.amount, comment: reading.comment, reading_id: reading.id, synched: true });
      };
    };
  };
};
