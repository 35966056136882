<template>
  <div>
    <slot :selectSupplierName="selectSupplierName"
          :selectMarketBased="selectMarketBased"
    >
    </slot>
    <template>
      <div class="row">
        <template v-if="!marketBased">
          <div class="form-group mt-20 ml-15">
            <label for="electricity_tariff_energy_supplier_id">Supplier name</label>
            <div class="select-wrapper">
              <select name="electricity_tariff[energy_supplier_id]" id="electricity_tariff_energy_supplier_id">
                <option
                  v-for="energySupplier in energySuppliers"
                  :key="energySupplier[1]"
                  :value="energySupplier[1]"
                  :selected="energySupplier[1] == supplier"
                  >{{ energySupplier[0] }}</option>
              </select>
            </div>
          </div>
        </template>

        <template v-if="marketBased">
          <div class="form-group mt-20 ml-15">
            <label for="electricity_tariff_market_based_supplier">Supplier name</label>
            <div class="select-wrapper">
              <select name="electricity_tariff[market_based_supplier]" id="electricity_tariff_market_based_supplier" v-on:change="selectSupplierName">
                <option
                  v-for="marketBasedSupplier in marketSuppliers"
                  :key="marketBasedSupplier"
                  :value="marketBasedSupplier"
                  :selected="marketBasedSupplier == supplier"
                  >{{ marketBasedSupplier }}</option>
              </select>
            </div>
          </div>

          <div class='form-group mt-20 ml-20'>
            <label for="electricity_tariff_tariff_name">Tariff name</label>
            <div class="select-wrapper">
              <select name="electricity_tariff[tariff_name]" id="electricity_tariff_tariff_name">
                <option
                  v-for="name in filteredTariffNames"
                  :key="name"
                  :value="name"
                  :selected="(defaultTariffName != null) && (name == tariffName)"
                  >{{ name ? name : 'General' }}</option>
              </select>
            </div>
          </div>
        </template>
      </div>
       <div class="form-group">
          <label for="electricity_tariff_market_based_yn">Use market based emission factor</label>
          <input type="checkbox"
                 :checked="marketBased"
                 :name="'electricity_tariff[market_based_yn]'"
                 :value="true"
                 :disabled="marketSuppliers.length == 0"
                 @change="selectMarketBased($event)">
          </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      energySuppliers: {
        type: Array,
        default: () => []
      },
      marketSuppliers: {
        type: Array,
        default: () => []
      },
      tariffNames: {
        type: Object,
        default: () => {}
      },
      defaultSupplier: String,
      defaultTariffName: String,
      defaultMarketBased: Boolean
    },
    data: function() {
      return {
        i18n: this.$parent.i18n,
        filteredTariffNames: Array,
        supplier: String,
        tariffName: String,
        marketBased: Boolean
      };
    },

    mounted: function() {
      this.supplier = this.defaultSupplier || this.energySuppliers[0][1];
      this.marketBased = this.defaultMarketBased || false;
      this.filteredTariffNames = this.tariffNames[this.supplier];
      this.tariffName = this.defaultTariffName;
    },

    methods: {
      selectMarketBased: function(event) {
        this.marketBased = event.target.checked;
        this.supplier = this.defaultSupplier || this.energySuppliers[0][1];
        this.filteredTariffNames = this.tariffNames[Object.keys(this.tariffNames)[0]];
        this.tariffName = this.defaultTariffName;
      },

      selectSupplierName: function(event) {
        this.supplier = event.target.value;
        this.filteredTariffNames = this.tariffNames[this.supplier];
        if (this.filteredTariffNames) {
          this.tariffName = this.filteredTariffNames[0][1]
        }
      }
    }
  }
</script>
