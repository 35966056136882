<template>
  <div v-if="!efficiencyBySize">
    <span>Benchmark Name</span>
    <div>
      <div class="select-wrapper benchmark-type">
        <select @change="urlModifier($event.target.value)" v-model="benchmarkType">
          <option v-for="availableBenchmarkType in availableBenchmarkTypes"
            :key="availableBenchmarkType.id"
            :value="availableBenchmarkType">
            {{ availableBenchmarkType }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      defaultParams: Object,
      availableBenchmarkTypes: Array,
    },
    data: function() {
      return {
        i18n: this.$parent.i18n,
        efficiencyBySize: null,
        benchmarkType: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);

        url.searchParams.set('benchmark_type', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.efficiencyBySize = (url.searchParams.get('efficiency_by_size') == 'true');
      this.benchmarkType = url.searchParams.get('benchmark_type') || this.defaultParams['benchmark_type'];
    },
  }
</script>
