// husbpot_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    target: String,
    region: String,
    portalId: String,
    formId: String,
  };

  initialize() {
    hbspt.forms.create({
      target: this.targetValue,
      region: this.regionValue,
      portalId: this.portalIdValue,
      formId: this.formIdValue,
    });
  }
}
