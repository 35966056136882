import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["postcodeInput", "countryInput", "currencyInput", "weatherStationInput", "weatherStationId"]
  static values = { useDegreeDaysApi: Boolean, url: String }

  connect() {
    this.toggleWeatherStationSelect();
  }

  selectDefaultCurrency() {
    const selectedIndex = this.countryInputTarget.selectedIndex
    const currecnyId = this.countryInputTarget.options[selectedIndex].dataset.currencyId;
    this.currencyInputTarget.value = currecnyId;
  }

  selectWeatherStation() {
    const postcode = this.postcodeInputTarget.value;
    const countryId = this.countryInputTarget.value;
    if (postcode == '') return;

    const url = new URL(this.urlValue);
    const params = {
      postcode: postcode,
      country_id: countryId
    }
    url.search = new URLSearchParams(params).toString();

    fetch(url, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then(data => this.setWeatherStationSelect(data))
      .catch(e => this.weatherStationInputTarget.value = '')
  }

  setWeatherStationSelect(response) {
    if (this.useDegreeDaysApiValue) {
      this.weatherStationInputTarget.value = response.weather_station.name;
      this.weatherStationIdTarget.value = response.weather_station.id;
    } else {
      this.weatherStationInputTarget.value = response.weather_station.id;
    }
  }

  toggleWeatherStationSelect() {
    if (this.useDegreeDaysApiValue) return;

    const selectedIndex = this.countryInputTarget.selectedIndex;
    const countryName = this.countryInputTarget.options[selectedIndex].text;

    if (countryName == 'United Kingdom') {
      this.weatherStationInputTarget.disabled = false;
    } else {
      this.weatherStationInputTarget.disabled = true;
    }
  }
}
