import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    const subgroupSelector = document.querySelector('[name="subgroup_id"]');
    const buildingSelector = document.querySelector('[name="building_id"]');
    const meterSelector = document.querySelector('[name="meter_id"]');
    const buildingAllOptions = JSON.parse(buildingSelector.dataset.allOptions);
    const meterAllOptions = JSON.parse(meterSelector.dataset.allOptions);
    const buildingOptions = JSON.parse(buildingSelector.dataset.options);
    const meterOptions = JSON.parse(meterSelector.dataset.options);
    const meterGroupOptions = JSON.parse(meterSelector.dataset.groupOptions);
    const controller = this;

    subgroupSelector.onchange = function () {
      controller.removeOptions(buildingSelector);
      controller.appendBuildings(buildingOptions, subgroupSelector.value, buildingSelector, buildingAllOptions);
      controller.removeOptions(meterSelector);
      controller.appendMeters(meterOptions, meterGroupOptions, subgroupSelector.value, buildingSelector.value, meterSelector, meterAllOptions);
    }

    buildingSelector.onchange = function () {
      controller.removeOptions(meterSelector);
      controller.appendMeters(meterOptions, meterGroupOptions, subgroupSelector.value, buildingSelector.value, meterSelector, meterAllOptions)
    }
  }

  removeOptions(selectElement) {
    const length = selectElement.options.length - 1;
    for (let i = length; i >= 0; i--) {
       selectElement.remove(i);
    }
  }

  appendBuildings(options, selectorValue, selector, allOptions) {
    this.appendOption({name: 'Building'}, selector)
    if (options[selectorValue]) {
      const buildings = JSON.parse(options[selectorValue]);
      for (let i = 0; i < buildings.length; i++) {
        const optionToShow = buildings[i];
        this.appendOption(optionToShow, selector);
      }
    } else {
      for (let i = 0; i < allOptions.length; i++) {
        const optionToShow = { id: allOptions[i][0], name: allOptions[i][1] }
        this.appendOption(optionToShow, selector);
      }
    }
  }

  appendMeters(options, groupOptions, subgroup, building, selector, allOptions) {
    this.appendOption({name: 'Meter'}, selector)
    if (options[building]) {
      const buildings = JSON.parse(options[building]);
      for (let i = 0; i < buildings.length; i++) {
        const optionToShow = buildings[i];
        this.appendOption(optionToShow, selector);
      }
    } else if (subgroup && groupOptions[subgroup]) {
      const subgroups = JSON.parse(groupOptions[subgroup]);
      for (let i = 0; i < subgroups.length; i++) {
        const optionToShow = subgroups[i];
        this.appendOption(optionToShow, selector);
      }
    } else {
      for (let i = 0; i < allOptions.length; i++) {
        const optionToShow = { id: allOptions[i][0], name: allOptions[i][1] }
        this.appendOption(optionToShow, selector);
      }
    }
  }

  appendOption(optionToShow, selector) {
    const option = document.createElement('option');
    option.text = optionToShow.name;
    if (optionToShow.id) option.value = optionToShow.id;
    selector.appendChild(option);
  }

}
