import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "amountInput"];
  static values = { previousAmount: Number };
  static outlets = ["modal"];

  validateReading(event) {
    if (this.amountInputTarget.value && this.hasPreviousAmountValue) {
      if (Number(this.amountInputTarget.value) < this.previousAmountValue) {
        this.modalOutlet.toggleModal(event);
      }
    }
  }

  checkOnlineStatus(event) {
    event.preventDefault();
    event.target.classList.add('disabled');
    let db;
    let controller = this;
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;

      getData();
    };

    function getData() {
      const meterId = parseInt(document.getElementById("reading_meter_id").value);
      const businessSlug = document.getElementById("business_slug").value;
      const currentReadingTakenAt = document.getElementById("reading_taken_at").value.split("-").reverse().join("-");
      const transaction = db.transaction(["readings"], "readwrite");
      const transactionMeters = db.transaction(["meters"], "readwrite");
      const objectStore = transaction.objectStore("readings");
      const objectStoreMeters = transactionMeters.objectStore("meters");
      const meterToTupdate = objectStoreMeters.get(meterId);
      const keyRange = IDBKeyRange.bound(parseInt(meterId), parseInt(meterId), false, false);
      const readingsIndex = objectStore.index("meter_id");
      const result = [];
      const amount = parseFloat(controller.amountInputTarget.value);

      readingsIndex.openCursor(keyRange).onsuccess = function (event) {

        const cursor = event.target.result;
        if (cursor) {
          result.push(cursor.value);
          cursor.continue();
        } else {
          if (result.length == 0) {
            if (window.navigator.onLine && localStorage.getItem("slowConnection") != "true") {
              document.getElementById("new_reading").submit();
            } else {
              objectStore.put({ id: `${meterId}-${amount}`, meter_id: meterId, taken_at: currentReadingTakenAt, amount: amount, reading_id: undefined, synched: false });
            }
            const data = meterToTupdate.result;
            data.manually_updated = true;
            const transactionMeters = db.transaction(["meters"], "readwrite");
            const objectStoreMeters = transactionMeters.objectStore("meters");
            objectStoreMeters.put(data)
          };
          for (let index = 0; index < result.length; index++) {
            const reading = result[index];
            if (reading.taken_at < currentReadingTakenAt) {
              if(reading.amount > parseFloat(controller.amountInputTarget.value)) {
                event.preventDefault();
                const readingInformation = document.getElementById("reading-information");
                const saveButton = document.getElementById("save-reading");
                readingInformation.textContent = `Reading: ${controller.amountInputTarget.value} kwh`
                controller.modalOutlet.toggleModal(event);
                if (saveButton) saveButton.classList.remove('disabled');
                break;
              } else {
                controller.updateManually(event)
              }
            } else {
              if (window.navigator.onLine && localStorage.getItem("slowConnection") != "true") {
                document.getElementById("new_reading").submit();
              } else {
                objectStore.put({ id: `${meterId}-${amount}`, meter_id: meterId, taken_at: currentReadingTakenAt, amount: amount, reading_id: undefined, synched: false, manually_updated: true });
                window.location.replace(`${window.location.origin}/mobile/businesses/${businessSlug}`)
              }
            }

            break;
          }
        }
      }
    }
  }

  updateManually(event) {
    event.preventDefault();
    if (event.target.classList != undefined) { event.target.classList.add('disabled'); }
    let db;
    let controller = this;
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;

      getData();
    };

    function getData() {
      const meterId = parseInt(document.getElementById("reading_meter_id").value);
      const businessSlug = document.getElementById("business_slug").value;
      const currentReadingTakenAt = document.getElementById("reading_taken_at").value.split("-").reverse().join("-");
      const transaction = db.transaction(["readings"], "readwrite");
      const transactionMeters = db.transaction(["meters"], "readwrite");
      const objectStore = transaction.objectStore("readings");
      const objectStoreMeters = transactionMeters.objectStore("meters");
      const meterToTupdate = objectStoreMeters.get(meterId);
      const keyRange = IDBKeyRange.bound(parseInt(meterId), parseInt(meterId), false, false);
      const readingsIndex = objectStore.index("meter_id");
      const amount = parseFloat(controller.amountInputTarget.value);

      readingsIndex.openCursor(keyRange).onsuccess = function (event) {
        if (window.navigator.onLine && localStorage.getItem("slowConnection") != "true") {
          document.getElementById("new_reading").submit();
        } else {
          objectStore.put({ id: `${meterId}-${amount}`, meter_id: meterId, taken_at: currentReadingTakenAt, amount: amount, reading_id: undefined, synched: false, manually_updated: true });
          window.location.replace(`${window.location.origin}/mobile/businesses/${businessSlug}`);
        }
        const data = meterToTupdate.result;
        data.manually_updated = true;
        const transactionMeters = db.transaction(["meters"], "readwrite");
        const objectStoreMeters = transactionMeters.objectStore("meters");
        objectStoreMeters.put(data)
      }
    }
  }

}
