<template>
  <div class="button-filters" v-if="!building">
    <span>Efficiency by size</span>
    <div :class="['icon filter-icon circled', { 'icon-active': efficiencyBySize }]" @click="urlModifier(!efficiencyBySize)">
      <inline-svg :src="require('images/icons/efficiency_by_size.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      building: {
        type: Boolean,
        default: false
      },
    },
    data: function() {
      return {
        efficiencyBySize: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);
        url.searchParams.set('efficiency_by_size', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.efficiencyBySize = (url.searchParams.get('efficiency_by_size') == 'true');
    },
    components: {
      InlineSvg,
    }
  }
</script>
