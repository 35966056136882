import '@hotwired/turbo-rails';
import '../controllers';

import Vue from 'vue/dist/vue.esm'
import i18n from '../utils/i18n'

import Chart from '../components/chart'
import Datepicker from '../components/datepicker'
import InlineSvg from 'vue-inline-svg';
import InteractiveInput from '../components/interactive_input'
import MarketBasedOptions from '../components/market_based_options'
import MeterSelect from '../components/meter_select'
import Modal from '../components/modal'
import SelectFilter from '../components/select_filter'
import SelectFilterTransport from '../components/select_filter_transport'

document.addEventListener('turbo:load', () => {
  const app = new Vue({
    el: '#page',
    components: {
      Chart,
      Datepicker,
      InlineSvg,
      InteractiveInput,
      MarketBasedOptions,
      MeterSelect,
      Modal,
      SelectFilter,
      SelectFilterTransport,
    },

    data: function() {
      return {
        i18n: i18n
      }
    }
  })
})
