import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "removeInput", "label", "icon", "actions", "modal", "modalImage"]
  static values = { image: String }

  connect() {
    if (this.imageValue) {
      this.updateImage(this.imageValue);
    }
  }

  previewImage() {
    const file = this.inputTarget.files[0];

    if (file && (/\.(jpe?g|png|gif)$/i.test(file.name))) {
      const reader = new FileReader();
      reader.addEventListener('load', this.changeImage.bind(this));
      reader.readAsDataURL(file);
    }
  }

  changeImage(event) {
    this.updateImage(event.target.result)
  }

  updateImage(source) {
    this.labelTarget.style.backgroundImage = `url(${source})`;
    this.modalImageTarget.src = source;
    this.iconTarget.classList.add('hidden');
    this.actionsTarget.classList.remove('hidden');
    this.removeInputTarget.value = false;
  }

  removeImage() {
    this.inputTarget.value = '';
    this.labelTarget.style.backgroundImage = 'url()';
    this.modalImageTarget.src = '';
    this.iconTarget.classList.remove('hidden');
    this.actionsTarget.classList.add('hidden');
    this.removeInputTarget.value = true;
  }

  openImage() {
    this.modalTarget.classList.remove('hidden');
  }

  closeImage() {
    this.modalTarget.classList.add('hidden');
  }
}
