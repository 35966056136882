import { Controller } from "@hotwired/stimulus";
import { debounce } from "debounce";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.getData();

    this.filterData = debounce(this.getData, 500)
  };

  getData() {
    let db;
    let controller = this;
    const DBOpenRequest = window.indexedDB.open("ReadingsAppDatabase", 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;

      this.getRows(db, controller)
    };
  };

  getRows(db, controller) {
    const businessId = parseInt(document.getElementById("business_id").value);
    const businessSlug = document.getElementById("business_slug").value;
    const dateFrom = document.getElementById("from").value;
    const filterDate = dateFrom;
    const transaction = db.transaction(["meters"], "readwrite");
    const objectStore = transaction.objectStore("meters");
    const keyRange = IDBKeyRange.bound(businessId, businessId, false, false);
    const metersIndex = objectStore.index("business_id");
    const waterTypes = ["water", "water_sewerage"];
    const environmentTypes = ["temperature", "humidity"];
    const energyTypes = ["gas", "electricity", "photovoltaic", "biomass", "cumulative_energy",  "heating_oil", "district_heating", "volume_flow", "t_combined", "valve_open", "target_room_temperature", "t_cost", "t_diesel", "t_car", "t_truck", "t_petrol", "biomas", "propane", "return_temp", "diesel", "petrol", "lpg", "photovoltaic_consumption", "instantaneous_power", "flow_temp", "hydropower", "coal"];
    const result = [];

    metersIndex.openCursor(keyRange).onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        result.push(cursor.value);
        cursor.continue();
      } else {
        if (result.length > 0) {
          const table = document.getElementById("meters_table");
          document.querySelectorAll("#meters_table a.meter-box").forEach(e => e.remove());

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const buildingParam = urlParams.get("building") || "";
          const subgroupParam = urlParams.get("subgroup") || "";
          const fuelGroupParam = urlParams.get("fuel_group") || "";
          const searchParam = controller.hasInputTarget ? controller.inputTarget.value : "";

          for (let index = 0; index < result.length; index++) {
            const meter = result[index];
            if (meter.manually_updated) continue;
            if (controller.invalidDate(meter, filterDate)) continue;
            if (searchParam != "" && !controller.validSearch(meter, searchParam)) continue;
            if (buildingParam && buildingParam != "" && buildingParam != meter.building_slug) continue;
            if (subgroupParam && subgroupParam != "" && subgroupParam != meter.subgroup_slug) continue;
            if (fuelGroupParam && fuelGroupParam != "" && fuelGroupParam != "all_impacts") {
              if (fuelGroupParam == "water" && !controller.isValidType(waterTypes, meter.type)) {
                continue
              } else if (fuelGroupParam == "environment" && !controller.isValidType(environmentTypes, meter.type)) {
                continue
              } else if (fuelGroupParam == "energy" && !controller.isValidType(energyTypes, meter.type)) {
                continue
              }
            }
            const ul = document.createElement("ul");
            ul.classList.add("list-group", "mb-3", "list-group-flush", "rounded-3");
            ul.setAttribute("id", `meters_list_${index}`);
            let link = document.createElement("a");
            link.setAttribute("id", `link_${index}`);
            link.classList.add("meter-box");
            link.href = `${window.location.origin}/mobile/businesses/${businessSlug}/readings/new?meter_id=${meter.id}&from=${dateFrom}&fuel_group=${fuelGroupParam}&building=${buildingParam}&subgroup=${subgroupParam}`;
            link.appendChild(ul)
            table.appendChild(link);
            document.getElementById(`link_${index}`).innerHTML

            document.getElementById(`meters_list_${index}`).innerHTML += `<li class="list-group-item bg-gray text-primary"><span class="fw-bold">Building:</span> ${meter.building_name}</li>`;
            document.getElementById(`meters_list_${index}`).innerHTML += `<li class="list-group-item bg-gray text-primary"><span class="fw-bold">Meter ID:</span> ${meter.name}</li>`;
            document.getElementById(`meters_list_${index}`).innerHTML += `<li class="list-group-item bg-gray text-primary"><span class="fw-bold">Type:</span> ${meter.type || ""}</li>`;
            document.getElementById(`meters_list_${index}`).innerHTML += `<li class="list-group-item bg-gray text-primary"><span class="fw-bold">Description:</span> ${meter.description || ""}</li>`;
          }
        }
      }
    };
  };

  isValidType(types, type) {
    return types.includes(type);
  }

  validSearch(meter, search) {
    search = search.toLowerCase();
    const building = meter.building_name.toLowerCase().includes(search);
    const id = meter.name.toLowerCase().includes(search);
    const type = meter.type.toLowerCase().includes(search);
    const description = (meter.description || "").toLowerCase().includes(search);
    return building || id || type || description;
  }

  invalidDate(meter, filterDate) {
    if (meter.last_reading_date === null) return false;
    let meterLastUpdatedDate = new Date(meter.last_reading_date)
    let day = filterDate.slice(0,2);
    let month = filterDate.slice(3,5);
    let year = filterDate.slice(6,10);
    filterDate = new Date(year, month - 1, day)
    meterLastUpdatedDate = new Date(meterLastUpdatedDate.setDate(meterLastUpdatedDate.getDate() + 1))
    return meterLastUpdatedDate >= filterDate;
  }
};
