<template>
  <div class="form-group d-flex align-items-end mb-0">
    <label v-if="label" class="w-auto">{{ label }}</label>

    <div class="select-wrapper date-filter">
      <input readonly="readonly"
             data-controller="datepicker"
             data-datepicker-target="input"
             :data-datepicker-options-value="datepickerOptions()"
             data-form-target="input"
             type="text"
             :name="name"
             :value="value"
             :placeholder="format.toUpperCase()"
             class="w-100 datepicker-input">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      name: String,
      value: [String, Date],
      daysOfWeekDisabled: {
        type: Array,
        default: []
      },
      format: [String, Function],
      pickLevel: {
        type: Number,
        default: 0
      },
      weekStart: {
        type: Number,
        default: 0
      },
    },
    methods: {
      datepickerOptions: function () {
        return JSON.stringify({
          daysOfWeekDisabled: this.daysOfWeekDisabled,
          format: this.format,
          maxDate: new Date().getTime(),
          pickLevel: this.pickLevel,
          weekStart: this.weekStart,
        })
      },
    },
  }
</script>
