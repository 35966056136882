<template>
  <div v-if="grouping" class="button-filters group-by">
    <span>Group by</span>
    <div :class="['icon filter-icon circled building', { 'icon-active': groupBy == 'building' || (subgroup && groupBy == 'group') || (countryCode && groupBy == 'country_code') }]" @click="urlModifier('building')">
      <inline-svg :src="require('images/icons/building_grouping.svg')"></inline-svg>
    </div>
    <div v-if="!subgroup" :class="['icon filter-icon circled group', { 'icon-active': groupBy == 'group' }]" @click="urlModifier('group')">
      <inline-svg :src="require('images/icons/group_grouping.svg')"></inline-svg>
    </div>
    <div v-if="!countryCode" :class="['icon filter-icon circled country', { 'icon-active': groupBy == 'country_code' }]" @click="urlModifier('country_code')">
      <inline-svg :src="require('images/icons/countries_grouping.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      defaultParams: Object,
      grouping: {
        type: Boolean,
        default: false
      },
    },
    data: function() {
      return {
        groupBy: null,
        subgroup: null,
        countryCode: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);

        if (value == 'country_code') {
          url.searchParams.delete('country_code');
          url.searchParams.delete('building');
          url.searchParams.set('estate', 'true');
        }

        url.searchParams.set('group_by', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.groupBy = url.searchParams.get('group_by') || this.defaultParams['group_by'];
      this.subgroup = url.searchParams.get('subgroup');
      this.countryCode = url.searchParams.get('country_code');
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
