import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item', 'tableItems'];
  static values = { gfpReport: Boolean};

  connect() {
    this.renderTableItems();
  }

  renderTableItems() {
    this.tableItemsTarget.innerHTML = this.itemTargets.map(item => {
      return `<p class="${this.valueClass()}">${item.dataset.value}</p>
              <p class="${this.keyClass()}">${item.dataset.key}</p>`;
    }).join('');
  }

  valueClass() {
    if (this.gfpReportValue) {
      return 'font-chivo text-primary fs-36px fw-6 mb-0';
    } else {
      return 'font-recoleta text-primary fs-36px fw-6 mb-0';
    }
  }

  keyClass() {
    if (this.gfpReportValue) {
      return 'font-lato text-primary fs-3 mb-4';
    } else {
      return 'font-recoleta text-primary fs-3 mb-4';
    }
  }
}
