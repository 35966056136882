<template>
  <div v-if="trends" class="button-filters">
    <span>Compare</span>
    <div :class="['icon filter-icon circled', { 'icon-active': compare == 'year_on_year' }]" @click="urlModifier('year_on_year')">
      <inline-svg :src="require('images/icons/yearly.svg')"></inline-svg>
    </div>
    <div :class="['icon filter-icon circled', { 'icon-active': compare == 'previous_month' }]" @click="urlModifier('previous_month')">
      <inline-svg :src="require('images/icons/monthly.svg')"></inline-svg>
    </div>
    <div :class="['icon filter-icon circled', { 'icon-active': compare == 'trends' }]" @click="urlModifier('trends')">
      <inline-svg :src="require('images/icons/daily.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    props: {
      defaultParams: Object,
      trends: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        compare: null,
      }
    },
    methods: {
      urlModifier: function(value) {
        let url = new URL(window.location.href);
        url.searchParams.set('compare', value);
        url.searchParams.sort();
        window.location.href = url;
      },
    },
    beforeMount: function() {
      let url = new URL(window.location.href);
      this.compare = url.searchParams.get('compare') || this.defaultParams['compare'];
    },
    components: {
      'inline-svg': InlineSvg,
    }
  }
</script>
